<template>
  <div class="contentBQ">
    <van-field v-model="fptt" :label="title" readonly @click="jumpEditInvoice" right-icon="edit"></van-field>
    <div class="tapOne">
      <div class="tapOne-div">
        <div class="time" @click.capture="showPopupFun()">
          <div>{{ yearText }}年</div>
          <img src="../../../assets/CostManager/sjx.png" />
        </div>
      </div>
      <div>
        <van-button type="info" size="20px" @click="queryInvoiceFun">查询</van-button>
      </div>
    </div>
    <div class="bill-list-box">
      <van-list v-model="listLoading" :finished="true" :finished-text="billTip">
        <div
          class="list-item"
          v-for="(item,index) in invoiceList"
          :key="index"
          @click="opentInvoiceDetailsFun(item.id)"
        >
          <div class="item-content">
            <div class="content-lie">
              <div class="lie yf">
                <span>发票抬头：</span>
                {{item.fp_tt}}
              </div>
            </div>
            <div class="content-lie">
              <div class="lie yf">
                <span>开票金额：</span>
                {{item.fp_je}}
              </div>
            </div>
            <div class="content-lie">
              <div class="lie yf">
                <span>开票时间：</span>
                {{item.create_time}}
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <van-popup v-model="isPopup" position="bottom" :style="{ height: '50%' }">
      <van-picker
        ref="picker"
        :title="pickerTitle"
        show-toolbar
        :columns="columns"
        :default-index="pickerIndex"
        @confirm="pickerConfirmFun"
        @cancel="isPopup=false"
      />
    </van-popup>
    <van-popup class="popup-details" v-model="isDetails" position="right" :style="{ width:'100%' }">
      <FpxqView :zdid="zdid" :isShow="isDetails"></FpxqView>
      <div class="details-cross" @click="isDetails=!isDetails">
        <van-icon name="cross" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getComputerTableApi, getAllInvoiceApi } from '../../../reuqest/api'
import FpxqView from './fpxq-view.vue'

export default {
  components: { FpxqView },
  data() {
    return {
      isDetails: false,
      listLoading: false,
      fptt: '',
      kplx: '',
      year: [],
      yearText: new Date().getFullYear(),
      billTip: '没有更多了',
      invoiceList: [],
      pickerTitle: '年份',
      columns: [],
      isPopup: false,
      pickerIndex: 0,
      zdid: '',
      invoiceList: [],
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    title() {
      return `发票抬头(${this.kplx == '1' ? '个人' : '单位'})`
    },
  },
  filters: {},
  created() {
    let minYear = 2018
    let currYear = new Date().getFullYear()
    let num = currYear - 0 + 1
    for (let index = minYear; index < num; index++) {
      this.year.push(index)
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const loadingToast = this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      await getComputerTableApi('1549579183084244994', { blade_user_id: this.userInfo.user_id })
        .then((res) => {
          let data = res.data.records[0]
          this.fptt = data.fptt ? data.fptt : ''
          this.kplx = data.kplx ? data.kplx : ''
        })
        .catch(() => {})
      await this.queryInvoiceFun()
      loadingToast.clear()
    },
    //显示弹窗
    showPopupFun() {
      this.columns = this.year
      this.columns.forEach((item, index) => {
        if (item == this.yearText) {
          this.pickerIndex = index
        }
      })
      this.isPopup = true
    },
    jumpEditInvoice() {
      this.$router.push('/InvoiceDetails')
    },
    queryInvoiceFun() {
      let obj = {
        yhid: this.userInfo.user_id,
        year: this.yearText,
      }
      getAllInvoiceApi(obj)
        .then((res) => {
          this.invoiceList = res.data
          if (this.invoiceList.length > 0) {
            this.billTip = '没有更多了~'
          } else {
            this.billTip = '暂无相关数据~'
          }
        })
        .catch(() => {})
    },
    //确认
    pickerConfirmFun() {
      let value = this.$refs.picker.getValues()[0]
      this.yearText = value
      this.isPopup = false
    },
    opentInvoiceDetailsFun(id) {
      this.zdid = id
      this.isDetails = true
    },
  },
}
</script>
 
<style lang="less" scoped>
.contentBQ {
  height: 100vh;
  background-color: rgb(245, 245, 245);
  /deep/ .van-field {
    .van-field__label {
      width: 100px !important;
    }
  }
}
.both {
  display: flex;
}
.time {
  background-color: #ccc;
}
.tapOne {
  display: flex;
  align-items: center;
  margin: 10px;
  .totalFee {
    flex: 1;
    text-align: right;
    color: #21a4fe;
    font-size: 14px;
  }
  .tapOne-div {
    .time {
      border-radius: 3px;
      border: 1px solid rgb(245, 245, 245);
      background-color: #fff;
      margin-right: 5px;
      height: 25px;
      padding: 3px 6px;
      display: flex;
      align-items: center;
      div {
        padding-right: 4px;
      }
      img {
        width: 10px;
      }
    }
  }
}
.van-button {
  height: 100%;
  padding: 5px 10px;
}
.poppicker {
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  border-bottom: 5px soild rgb(245, 245, 245);
  .poppicker-one {
    padding: 10px;
  }
  .poppicker-two {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    span {
      font-size: 14px;
      width: 49%;
      margin-left: 3px;
    }
  }
}
.bill-list-box {
  background-color: #f5f5f5;
  .list-item {
    background-color: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px 0;
    .item-img {
      margin: 0 10px;
      img {
        width: 45px;
      }
    }
    .item-content {
      width: 100%;
      padding: 0 15px;
      .je {
        color: #ffb54c;
      }
      .content-lie {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        padding: 3px 0;
        color: #232323;
        text-align: left;
        .lie {
          flex: 1;
        }
        span {
          color: #999;
        }
      }
    }
  }
}
.popup-details {
  .details-cross {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    height: 47px;
    width: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>