<template>
    <div>
       <van-notice-bar
  left-icon="records" :scrollable="false"
  text="发票填写点击这里"
  @click="jumpInvoiceDetails"
/>
    </div>
</template>

<script>
export default {
    data () {
        return {

 
        }
    },
    methods:{
        jumpInvoiceDetails(){
            this.$router.push('/InvoiceDetails')
        }
    }
}
</script>
 
<style lang = "less" scoped>
    .van-notice-bar{
        height: 3.125rem /* 50/16 */;
    }
</style>