<template>
  <div class="contentBqd">
    <div class="details-top">发票详情</div>
    <div class="details-content-box">
      <div>
        <div class="ulCon">
          <div class="ulCon-item">
            <div>开票类型:</div>
            <div>{{invoiceData.kplx=='1'?'个人':'单位'}}</div>
          </div>
          <div class="ulCon-item">
            <div>发票类型:</div>
            <div>{{invoiceData.fplx=='1'?'电子发票':'专用发票'}}</div>
          </div>
          <div class="ulCon-item">
            <div>发票抬头:</div>
            <div>{{invoiceData.fptt}}</div>
          </div>
          <div class="ulCon-item">
            <div>手机号:</div>
            <div>{{invoiceData.fpsjh}}</div>
          </div>
          <div class="ulCon-item">
            <div>邮箱:</div>
            <div>{{invoiceData.fpyx}}</div>
          </div>
          <div class="ulCon-item">
            <div>开户行:</div>
            <div>{{invoiceData.khh}}</div>
          </div>
          <div class="ulCon-item">
            <div>开户账号:</div>
            <div>{{invoiceData.khzh}}</div>
          </div>
          <div class="ulCon-item">
            <div>地址:</div>
            <div>{{invoiceData.dz}}</div>
          </div>
        </div>
      </div>
      <div>
        <van-button round type="info" style="margin-top:20px" @click="lookInvoice">查看电子发票</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getInvoiceApi, getComputerTableApi, getInvoiceDetailsApi } from '@/reuqest/api.js'
export default {
  props: {
    zdid: [String,Number],
    isShow: {
      type: Boolean,
    },
  },
  data() {
    return {
      invoiceData: {},
    }
  },
  watch: {
    isShow: {
      handler(value) {
        if (value) this.init()
      },
      immediate: true,
    },
  },
  methods: {
    init() {
      const loadingToast = this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      // getComputerTableApi('1610825285328769025', {
      //   zhck_log_zdjl_id: zdid,
      // }).then((res) => {
      //   this.invoiceData = res.data.records[0]
      //   loadingToast.clear()
      // })
      console.log('==========',this.zdid)
      getInvoiceDetailsApi({
        zdid: this.zdid,
      }).then((res) => {
        console.log('=========>',res)
        this.invoiceData = res.data
        loadingToast.clear()
      })
    },
    lookInvoice() {
      getInvoiceApi({ zdid: this.invoiceData.id }).then((res) => {
        let url = res.data.qrcodepath
        if (url) {
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.style.display = 'none'
          a.href = url
          a.id = 'dzfp'
          a.click()
          document.getElementById('dzfp').remove()
        }
      })
    },
  },
  created() {},
}
</script>
 
<style lang="less" scoped>
.details-top {
  font-size: 16px;
  background-color: #fff;
  height: 47px;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.details-content-box {
  height: calc(100vh - 47px);
  overflow-y: auto;
}
.contentBqd {
  font-size: 16px;
  height: 100vh;
  background-color: rgb(245, 245, 245);
}
* {
  margin: 0;
  padding: 0;
}
.ulCon {
  padding: 10px;
  background-color: #fff;
  border-bottom: 5px solid rgb(245, 245, 245);
  .ulCon-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px;
    color: #666;
    span {
      color: #232323;
    }
  }
}
.twoCon {
  background-color: #fff;
}
.tbCon {
  padding-bottom: 10px;
  border-bottom: 5px solid rgb(245, 245, 245);
  table {
    width: 90%;
    text-align: center;
    td {
      padding: 2px 0;
    }
  }
}
.twoCon-f {
  border: none;
}
.threeCon {
  margin-bottom: 10px;
  background-color: #fff;
  padding: 10px 0;
  .threeCon-child {
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    .child-title {
      color: #999;
    }
  }
}
.van-button__content {
  width: 65vw !important;
}
</style>